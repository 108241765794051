import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { S1Table, S1ButtonType, S1UIService } from '@app/s1';
import { INegozio } from '@app/shared/models/negozio';
import { ITask } from '@app/shared/models/task';
import { ITaskNegozio, StatoTaskNegozio } from '@app/shared/models/task-negozio';
import { TaskNegozioService, IRicercaTaskNegozioParams } from '@app/shared/services/task-negozio.service';
import { TaskModalComponent } from '../task-modal/task-modal.component';
import { UtenteService } from '@app/shared';
import { UtilityService } from '@app/shared/services/utility.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'iwr-tabella-task-negozi',
  templateUrl: './tabella-task-negozi.component.html',
  styleUrls: ['./tabella-task-negozi.component.scss']
})
export class TabellaTaskNegoziComponent {

  @Input() set negozio(newValue: Partial<INegozio>) {
    //this._negozio = newValue;
    this.searchForm.controls.negozio.patchValue(newValue?.id);
    this.search();
  }

  @Input() set task(newValue: Partial<ITask>) {
    //this._task = newValue;
    this.searchForm.controls.task.patchValue(newValue?.id);
    this.search();
  }

  @Input() grouped: boolean = true;

  @Output() needsRefresh = new EventEmitter<boolean>();

  @ViewChild('table') table: S1Table;
  @ViewChild('taskModal') taskModal: TaskModalComponent;

  //_task: Partial<ITask>;
  //_negozio: Partial<INegozio>;

  s1ButtonType = S1ButtonType;
  statoTaskNegozio = StatoTaskNegozio;

  searchForm: UntypedFormGroup;

  isNegozio: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private router: Router, private utenteService: UtenteService, private taskNegozioService: TaskNegozioService, private datepipe: DatePipe, private utility: UtilityService, private ui: S1UIService) { 

    this.searchForm = this.formBuilder.group({
      'oggetto': [null, null],
      'task': [null, null],
      'negozio': [null, null],
      'priorita': [null, null],
      'stato': [null, null],
      'daGiorno': [null, null],
      'aGiorno': [null, null],
      'archiviati': [null, null]
    })

    this.utenteService.getCurrentUser().subscribe(user => {

      this.isNegozio = user?.codiceOperatoreRichiesto;

    })

  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.table.config.sorting.sortBy = "data_ultima_modifica";
    this.table.config.sorting.isSortAscending = false; 
    this.search();
  }

  search() {

    if (!this.table) {
      return;
    }

    const negozio: Partial<INegozio> = { id: this.searchForm.controls.negozio.value };
    const task: Partial<ITask> = { id: this.searchForm.controls.task.value, oggetto: this.searchForm.controls.oggetto.value, priorita: this.searchForm.controls.priorita.value };
    const stato = this.searchForm.controls.stato.value ? StatoTaskNegozio[this.searchForm.controls.stato.value] : null;

    let params: IRicercaTaskNegozioParams = {
      page: this.table?.config.pagination.page.page,
      pageSize: +this.table?.config.pagination.page.itemsPerPage,
      sort: this.table.sortBy(),
      negozio: negozio,
      task: task,
      stato: stato,
      dataDa: this.datepipe.transform(this.utility.UTCDateFromLocal(this.searchForm.controls.daGiorno.value), "yyyy-MM-dd"),
      dataA: this.datepipe.transform(this.utility.UTCDateFromLocal(this.searchForm.controls.aGiorno.value), "yyyy-MM-dd")
    }

    const api$ = stato == StatoTaskNegozio.archiviato ? this.taskNegozioService.ricercaArchiviati(params) : this.taskNegozioService.ricerca(params);

    api$.subscribe(tasksPaginati => {

      this.table.updateData(tasksPaginati.elements, tasksPaginati.paginationInfo);

    })

  }

  new() {
    this.router.navigate(["/task/nuovo"]);
    //this.taskModal.open(null);
  }

  openTask(taskNegozio: ITaskNegozio, setInLavorazione: boolean) {
    this.taskModal.open(taskNegozio, setInLavorazione);
  }

  /*askDelete(task: ITaskNegozio) {

    this.ui.showDialogPopup("tasks.messages.delete").then(result => {
      if (result.value) {
        this.delete(task);
      }
    })

  }

  private delete(taskToDelete: ITaskNegozio) {

    this.taskNegozioService.elimina(taskToDelete.id).subscribe(deleted => {

      if (deleted) {
        this.ui.showSuccessToast("tasks.messages.deleted");
        this.search();
      }

    })

  }*/

  askAction(taskNegozio: ITaskNegozio, action: StatoTaskNegozio) {

    this.ui.showDialogPopup(`tasks.messages.${action}`).then(result => {
      if (result.value) {
        this.action(taskNegozio, action);
      }
    })

  }

  private action(taskNegozio: ITaskNegozio, action: StatoTaskNegozio) {

    this.taskNegozioService.cambiaStato(taskNegozio.id, action).subscribe(done => {

      if (done) {
        this.ui.showSuccessToast(`tasks.messages.${action}Done`);
        this.search();
      }

    })

  }

  reload(isArchiviato: boolean) {
    if (isArchiviato) {
      this.needsRefresh.emit(true);
    }
    this.search();
  }

}
