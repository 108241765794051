<s1-card class="mt-2" *ngIf="!grouped">
  <s1-form [form]="searchForm" [table]="table" (onClickSearch)="search()" (onClickReset)="search()">
    <div class="row">
<!--<s1-input-text [control]="searchForm.controls.task" [label]="'tasks.fields.id'" class="col"></s1-input-text>-->
      <s1-input-text [control]="searchForm.controls.oggetto" [label]="'tasks.fields.oggetto'" class="col"></s1-input-text>
      <iwr-negozi-select class="col" [control]="searchForm.controls.negozio"></iwr-negozi-select>
      <iwr-priorita-select class="col" [control]="searchForm.controls.priorita"></iwr-priorita-select>
      <iwr-stato-task-select class="col" [control]="searchForm.controls.stato"></iwr-stato-task-select>
    </div>
    <div class="row">
      <s1-input-datepicker [control]="searchForm.controls.daGiorno" [label]="'tasks.fields.daGiorno'" class="col"></s1-input-datepicker>
      <s1-input-datepicker [control]="searchForm.controls.aGiorno" [label]="'tasks.fields.aGiorno'" class="col"></s1-input-datepicker>
      <div class="col"></div>
      <div class="col"></div>
    </div>
  </s1-form>
</s1-card>

<s1-card class="mt-2">
  <s1-table #table [onlinePagination]="true" (pageChanged)="search()">
    <thead header>
      <tr>
        <th> # </th>
        <th *ngIf="!grouped"  s1-sortable-column sortable-column="task_oggetto">{{ 'tasks.table.columns.task' | translate }}</th>
        <th s1-sortable-column sortable-column="negozio_nome">{{ 'tasks.table.columns.negozio' | translate }}</th>
        <!--<th *ngIf="!grouped" s1-sortable-column sortable-column="task_descrizione">{{ 'tasks.table.columns.descrizione' | translate }}</th>-->
        <th s1-sortable-column sortable-column="data_ultima_risposta" *ngIf="!grouped">{{ 'tasks.table.columns.priorita' | translate }}</th>
        <th s1-sortable-column sortable-column="data_creazione">{{ 'tasks.table.columns.dataCreazione' | translate }}</th>
        <th s1-sortable-column sortable-column="data_ultima_modifica">{{ 'tasks.table.columns.dataUltimaModifica' | translate }}</th>
        <!--<th *ngIf="!grouped">{{ 'tasks.table.columns.utente' | translate }}</th>-->
        <th s1-sortable-column sortable-column="utente_ultima_modifica">{{ 'tasks.table.columns.utenteUltimaModifica' | translate }}</th>
        <!--<th s1-sortable-column sortable-column="data_ultima_risposta">{{ 'tasks.table.columns.dataUltimaRisposta' | translate }}</th>
        <th s1-sortable-column sortable-column="utente_ultima_risposta">{{ 'tasks.table.columns.utenteUltimaRisposta' | translate }}</th>-->
        <!--<th *ngIf="!grouped">{{ 'tasks.table.columns.categorie' | translate }}</th> -->
        <th *ngIf="!grouped" s1-sortable-column sortable-column="stato">{{ 'tasks.table.columns.stato' | translate }}</th>  
        <th *ngIf="grouped">{{ 'tasks.table.columns.stato' | translate }}</th>       
        <th></th>                                     
      </tr>
    </thead>
    <tbody body>
      <tr *ngFor="let taskNegozio of table.config.rows; let i = index">
        <td> {{ taskNegozio.task?.idProgressive }} </td>
        <td *ngIf="!grouped"> {{ taskNegozio.task?.oggetto }}</td>
        <td> {{ taskNegozio.negozio?.nome }} </td>
        <!--<td *ngIf="!grouped"> {{ taskNegozio.task?.descrizione }}</td>-->
        <td *ngIf="!grouped">
          <iwr-priorita-tag [priorita]="taskNegozio?.task.priorita"></iwr-priorita-tag>
        </td>
        <td>{{ taskNegozio?.task?.dataCreazione | s1ReadableDate: true }}</td>
        <td>{{ taskNegozio?.dataUltimaModifica | s1ReadableDate: true }}</td>
        <!--<td *ngIf="!grouped"> {{ taskNegozio?.task.utenteCreatoDa?.nomeCompleto }} </td>-->
        <td> {{ taskNegozio?.task.utenteModificatoDa?.nomeCompleto }} </td>
        <!--<td>{{ taskNegozio?.dataUltimaRisposta | s1ReadableDate: true }}</td>
        <td> {{ taskNegozio?.utenteUltimaRisposta?.nomeCompleto }} </td>-->
        <!--<td *ngIf="!grouped">
          <iwr-categoria-tag class="pr-2" *ngFor="let categoria of taskNegozio?.task.categorie" [categoria]="categoria"></iwr-categoria-tag>
        </td>-->
        <td><iwr-stato-tag [stato]="taskNegozio?.stato"></iwr-stato-tag></td>
        <td class="text-right">
          <s1-button *ngIf="taskNegozio?.stato == statoTaskNegozio.pubblicato" [type]="s1ButtonType.Unlink" (onClick)="openTask(taskNegozio, true)" [label]="'tasks.buttons.gestisci'" [icon]="'fas fa-power-off'"></s1-button>
          <s1-button *ngIf="taskNegozio?.stato != statoTaskNegozio.pubblicato" [type]="s1ButtonType.View" (onClick)="openTask(taskNegozio, false)" class="ml-2"></s1-button>
          <!--<s1-button *ngIf="(!isNegozio && taskNegozio?.stato == statoTaskNegozio.pubblicato) || taskNegozio?.stato == statoTaskNegozio.inLavorazione || taskNegozio?.stato == statoTaskNegozio.inRevisione" [type]="s1ButtonType.Edit" (onClick)="openTask(taskNegozio, isNegozio)" [label]="'tasks.buttons.rispondi'" [icon]="'fas fa-comment-alt'" class="ml-2"></s1-button>
          <s1-button *ngIf="taskNegozio?.stato == statoTaskNegozio.inLavorazione || taskNegozio?.stato == statoTaskNegozio.inRevisione" [type]="s1ButtonType.Link" (onClick)="askAction(taskNegozio, statoTaskNegozio.completato)" [label]="'tasks.buttons.completa'" [icon]="'fab fa-font-awesome-flag'" class="ml-2"></s1-button>
          <s1-button *ngIf="taskNegozio?.stato == statoTaskNegozio.completato || taskNegozio?.stato == statoTaskNegozio.chiuso" [type]="s1ButtonType.View" (onClick)="openTask(taskNegozio, false)" class="ml-2"></s1-button>
          <s1-button *ngIf="!isNegozio && taskNegozio?.stato == statoTaskNegozio.completato" [type]="s1ButtonType.Delete" (onClick)="askAction(taskNegozio, statoTaskNegozio.inRevisione)" [label]="'tasks.buttons.revisione'" [icon]="'fas fa-exclamation-triangle'" class="ml-2"></s1-button>
          <s1-button *ngIf="!isNegozio && taskNegozio?.stato == statoTaskNegozio.completato" [type]="s1ButtonType.Cancel" (onClick)="askAction(taskNegozio, statoTaskNegozio.chiuso)" [label]="'tasks.buttons.chiudi'" [icon]="'fas fa-check'" class="ml-2 "></s1-button>-->
        </td>
      </tr>
    </tbody>
  </s1-table>
</s1-card>

<iwr-task-modal #taskModal (elementSaved)="reload($event)"></iwr-task-modal>