
<s1-card class="mt-2">
  <s1-form [form]="searchForm" [table]="table" (onClickSearch)="search()" (onClickReset)="search()">
    <div class="row">
      <!--<s1-input-text [control]="searchForm.controls.task" [label]="'tasks.fields.id'" class="col"></s1-input-text>-->
      <s1-input-text [control]="searchForm.controls.oggetto" [label]="'tasks.fields.oggetto'" class="col"></s1-input-text>
      <iwr-priorita-select class="col" [control]="searchForm.controls.priorita"></iwr-priorita-select>
      <s1-input-datepicker [control]="searchForm.controls.daGiorno" [label]="'tasks.fields.daGiorno'" class="col"></s1-input-datepicker>
      <s1-input-datepicker [control]="searchForm.controls.aGiorno" [label]="'tasks.fields.aGiorno'" class="col"></s1-input-datepicker>
    </div>
  </s1-form>
</s1-card>

<s1-card class="mt-2">
  <s1-table #table [onlinePagination]="true" (pageChanged)="search()">
    <thead header>
      <tr>
        <th s1-sortable-column sortable-column="id_internal"> # </th>
        <th s1-sortable-column sortable-column="oggetto">{{ 'tasks.table.columns.oggetto' | translate }}</th>
        <!--<th>{{ 'tasks.table.columns.descrizione' | translate }}</th>-->
        <th s1-sortable-column sortable-column="priorita">{{ 'tasks.table.columns.priorita' | translate }}</th>
        <th s1-sortable-column sortable-column="data_creazione">{{ 'tasks.table.columns.dataCreazione' | translate }}</th>
        <th s1-sortable-column sortable-column="data_ultima_modifica">{{ 'tasks.table.columns.dataUltimaModifica' | translate }}</th>
        <th s1-sortable-column sortable-column="utente_ultima_modifica">{{ 'tasks.table.columns.utenteUltimaModifica' | translate }}</th>
        <!--<th>{{ 'tasks.table.columns.categorie' | translate }}</th>-->        
        <th></th>                                     
      </tr>
    </thead>
    <tbody body>
      <ng-container *ngFor="let task of table.config.rows; let i = index">
        <tr >
          <td> {{ task.idProgressive }} </td>
          <td> {{ task.oggetto }} </td>
          <!--<td> {{ task.descrizione }} </td>-->
          <td>
            <iwr-priorita-tag [priorita]="task.priorita"></iwr-priorita-tag>
          </td>
          <td> {{ task.dataCreazione | s1ReadableDate: true }} </td>
          <td> {{ task.dataUltimaModifica | s1ReadableDate: true }} </td>
          <td> {{ task.utenteModificatoDa.nomeCompleto }}</td>
          <!--<td>
            <iwr-categoria-tag class="pr-2" *ngFor="let categoria of task.categorie" [categoria]="categoria"></iwr-categoria-tag>
          </td>-->
          <td class="text-right">
            <s1-button  *ngIf="task.statoGlobale == statoTaskNegozio.daFare" [type]="s1ButtonType.Edit" (onClick)="edit(task)" class="mr-2"></s1-button>
            <!--<s1-button [type]="s1ButtonType.Delete" (onClick)="askDelete(task)" class="mr-2"></s1-button>-->
            <s1-button *ngIf="task.statoGlobale == statoTaskNegozio.daFare" [type]="s1ButtonType.Edit" [label]="'tasks.buttons.pubblica'" [icon]="'fas fa-pen-nib'" (onClick)="askPublish(task)" class="mr-2"></s1-button>
            <s1-button-expand [(expand)]="task.expand"></s1-button-expand>
          </td>
        </tr>
        <tr *ngIf="task.expand">
          <td colspan="7">
            <iwr-tabella-task-negozi [task]="task" (needsRefresh)="search()"></iwr-tabella-task-negozi>
          </td>
        </tr>
      </ng-container>
      
    </tbody>
  </s1-table>
</s1-card>